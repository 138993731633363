import './App.css';
import React, {  } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import LogIn from './Pages/LogIn'
import VenditeHome from './Pages/Vendite/VenditeHome'
import Home from './Pages/Home'
import Scontrino from './Pages/Vendite/Scontrino/Scontrino'
import VenditeFasceOrario from './Pages/Vendite/Fasce Orario/Vendite/VenditeFasceOrario'
import FinanziarioIva from './Pages/Vendite/Finanziario/Iva/Iva'
import FinanziarioTenderPagamento from './Pages/Vendite/Finanziario/Tender Pagamento/TenderPagamento'
import Reparto from './Pages/Vendite/Reparto/Reparto'
import RepartoFasceOrario from './Pages/Vendite/Fasce Orario/Reparto/RepartoFasceOrario'
import AnomalieFidelity from './Pages/Vendite/Fidelity/Anomalie/AnomalieFidelity'
import IncassoGiornaliero from 'Pages/Vendite/Incasso/IncassoGiornaliero';
import IncassoOperatore from 'Pages/Vendite/Operatore/Incasso/IncassoOperatore';
import ImpostazioniOperatore from 'Pages/Vendite/Operatore/Impostazioni/ImpostazioniOperatore';
import GeoPosPresenze from 'Pages/Vendite/GeoPos/Presenze/GeoPosPresenze';
import ObiettiviReparto from 'Pages/Vendite/Obiettivi/ObiettiviReparto/ObiettiviReparto';
import ObiettiviSettore from 'Pages/Vendite/Obiettivi/ObiettiviSettore/ObiettiviSettore';
import VendutoGiornalieroArticolo from 'Pages/Vendite/Articolo/VendutoGiornaliero/VendutoGiornalieroArticolo';
import OperatoreFasceOrario from 'Pages/Vendite/Fasce Orario/Operatore/OperatoreFasceOrario';
import VendutoGiornalieroArticoloOperatore from 'Pages/Vendite/Articolo/VendutoGiornalieroOperatore/VendutoGiornalieroArticoloOperatore';
import VendutoGiornalieroArticoloOperatoreScontrino from 'Pages/Vendite/Articolo/VendutoGiornalieroOperatoreScontrino/VendutoGiornalieroOperatoreScontrino';

import BilanceFasceOrarie from 'Pages/Bilance/FasceOrarie/BilanceFasceOrarie';
import BilanceReparto from 'Pages/Bilance/Reparto/BilanceReparto';
import BilancePLU from 'Pages/Bilance/PLU/BilancePLU';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let history = useHistory();

  const redirectHome = () => {
    history.push('/');
  }

  return (
        <Router >
          <Switch>
              <Route exact path='/' component={LogIn} />
              <Route exact path='/:idCliente/' component={VenditeHome} />
              <Route exact path='/vendite/:idCliente/Incassi' component={IncassoGiornaliero} />
              <Route exact path='/vendite/:idCliente/Scontrini/' component={Scontrino} />
              <Route exact path='/vendite/:idCliente/Operatori/Impostazioni' component={ImpostazioniOperatore} />
              <Route exact path='/vendite/:idCliente/Operatori/Incassi' component={IncassoOperatore} />
              <Route exact path='/vendite/:idCliente/:idNegozio/Scontrini/:dataScontrino/:idCassa/:idScontrino' component={Scontrino} />
              <Route exact path='/vendite/:idCliente/Report/FasceOrarie' component={VenditeFasceOrario} />
              <Route exact path='/vendite/:idCliente/Report/FasceOrarie/Reparti' component={RepartoFasceOrario} />
              <Route exact path='/vendite/:idCliente/Report/FasceOrarie/Operatori' component={OperatoreFasceOrario} />
              <Route exact path='/vendite/:idCliente/Report/Reparti' component={Reparto} />
              <Route exact path='/vendite/:idCliente/Report/Finanziario/Iva' component={FinanziarioIva} />
              <Route exact path='/vendite/:idCliente/Report/Finanziario/TenderPagamento' component={FinanziarioTenderPagamento} />
              <Route exact path='/vendite/:idCliente/Report/Fidelity/Anomalie' component={AnomalieFidelity} />
              <Route exact path='/vendite/:idCliente/GeoPos/Presenze' component={GeoPosPresenze} />
              <Route exact path='/vendite/:idCliente/Report/Obiettivi/Reparti' component={ObiettiviReparto} />
              <Route exact path='/vendite/:idCliente/Report/Obiettivi/Settori' component={ObiettiviSettore} />
              <Route exact path='/vendite/:idCliente/Report/Articoli/VendutoGiornaliero' component={VendutoGiornalieroArticolo} />
              <Route exact path='/vendite/:idCliente/Report/Articoli/VendutoGiornaliero/Operatore' component={VendutoGiornalieroArticoloOperatore} />
              <Route exact path='/vendite/:idCliente/Report/Articoli/VendutoGiornaliero/Operatore/Scontrino' component={VendutoGiornalieroArticoloOperatoreScontrino} />
              {/*  */}
              <Route exact path='/bilance/:idCliente/Report/Reparti' component={BilanceReparto} />
              <Route exact path='/bilance/:idCliente/Report/PLU' component={BilancePLU} />
              <Route exact path='/bilance/:idCliente/Report/FasceOrarie' component={BilanceFasceOrarie} />
          </Switch>
        </Router>
     
  )
}

export default App;
